import AgeGate from '@/stories/modals/age-gate/age-gate'
import Announcement from '@/stories/modals/announcement/announcement'
import AppOutdated from '@/stories/modals/app-outdated/app-outdated'
import CartExpired from '@/stories/modals/cart-expired/cart-expired'
import CartSizeMismatch from '@/stories/modals/cart-size-mismatch/cart-size-mismatch'
import CashOnlyModal from '@/stories/modals/cash-only/cash-only'
import ChangedPassword from '@/stories/modals/changed-password/changed-password'
import CollectiveAgreement from '@/stories/modals/collective-agreement/collective-agreement'
import DepotMismatch from '@/stories/modals/depot-mismatch/depot-mismatch'
import DuplicateAccountDetected from '@/stories/modals/duplicate-account-detected/duplicate-account-detected'
import ExistingAccountIdentifier from '@/stories/modals/existing-account-identifier/existing-account-identifier'
import LocationOutOfService from '@/stories/modals/location-out-of-service/location-out-of-service'
import NonExistentAccountIdentifier from '@/stories/modals/non-existent-account-identifier/non-existent-account-identifier'
import PhoneVerificationIncomplete from '@/stories/modals/phone-verification-incomplete/phone-verification-incomplete'
import RemovedPromo from '@/stories/modals/removed-promo/removed-promo'
import ShowIDs from '@/stories/modals/show-ids/show-ids'
import Modal2FA from '@/stories/modals/two-factor-auth/2FA-modal'
import UnderAgeMinimum from '@/stories/modals/under-age-minimum/under-age-minimum'
import UnderMinimum from '@/stories/modals/under-minimum/under-minimum'
import UploadError from '@/stories/modals/upload-error/upload-error'
import VerificationDeclined from '@/stories/modals/verification-declined/verification-declined'
import { EMAIL } from '@helpers/constants'
import { alertTypes } from 'redux/alert/config/types'

const Modal = ({
  abortCheckoutNewDepot,
  acceptCollectiveAgreement,
  activeDepot,
  alertKey,
  cartMinimum,
  clearPotentialAddress,
  declinedComment,
  declinedReasons,
  logout,
  newestAnnouncement,
  promoCode,
  recUrl,
  resetCartSizeMismatch,
  resetCurrentAction,
  resetDepotMismatch,
  router,
  setAlertTypeVisibility,
  setChangePassword,
  setCookie,
  setDismissedAnnouncements,
  setExpiredCart,
  toggleAddress,
  toggleRemovedPromo,
  verificationProcessErrors
}) => {
  let modal

  switch (alertKey) {
    case alertTypes.ANNOUNCEMENT:
      modal = (
        <Announcement newestAnnouncement={newestAnnouncement} setDismissedAnnouncements={setDismissedAnnouncements} />
      )
      break

    case alertTypes.AGE_GATE:
      modal = <AgeGate setCookie={setCookie} />
      break

    case alertTypes.CART_EXPIRED:
      modal = (
        <CartExpired
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          setExpiredCart={setExpiredCart}
        />
      )
      break

    case alertTypes.CART_SIZE_MISMATCH:
      modal = (
        <CartSizeMismatch
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          resetCartSizeMismatch={resetCartSizeMismatch}
          router={router}
        />
      )
      break

    case alertTypes.CHANGED_PASSWORD:
      modal = <ChangedPassword setChangePassword={setChangePassword} />
      break

    case alertTypes.COLLECTIVE_AGREEMENT_NOT_SIGNED:
      modal = (
        <CollectiveAgreement
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          acceptCollectiveAgreement={acceptCollectiveAgreement}
        />
      )
      break

    case alertTypes.CASH_ONLY:
      modal = (
        <CashOnlyModal
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          resetCurrentAction={resetCurrentAction}
          activeDepot={activeDepot}
        />
      )
      break

    case alertTypes.DEPOT_MISMATCH:
      modal = (
        <DepotMismatch
          abortCheckoutNewDepot={abortCheckoutNewDepot}
          alertKey={alertKey}
          promoCode={promoCode}
          resetDepotMismatch={resetDepotMismatch}
          setAlertTypeVisibility={setAlertTypeVisibility}
        />
      )
      break

    case alertTypes.EXISTING_EMAIL_ADDRESS:
      modal = (
        <ExistingAccountIdentifier
          router={router}
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          identifierType={EMAIL}
          logout={logout}
        />
      )
      break

    case alertTypes.EXISTING_PHONE_NUMBER:
      modal = (
        <ExistingAccountIdentifier
          router={router}
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          logout={logout}
        />
      )
      break

    case alertTypes.IMAGE_UPLOAD_ERROR:
      modal = (
        <UploadError
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          verificationProcessErrors={verificationProcessErrors.upload}
        />
      )
      break

    case alertTypes.NEED_TO_SHOW_ID:
      modal = <ShowIDs alertKey={alertKey} recUrl={recUrl} setAlertTypeVisibility={setAlertTypeVisibility} />
      break

    case alertTypes.NEED_2FA_REAUTH:
      modal = <Modal2FA />
      break

    case alertTypes.HAS_LINKED_ACCOUNTS:
      modal = (
        <DuplicateAccountDetected
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          resetCurrentAction={resetCurrentAction}
        />
      )
      break

    case alertTypes.NON_EXISTENT_EMAIL:
      modal = (
        <NonExistentAccountIdentifier
          alertKey={alertKey}
          router={router}
          setAlertTypeVisibility={setAlertTypeVisibility}
        />
      )
      break

    case alertTypes.LOCATION_OUT_OF_SERVICE:
      modal = (
        <LocationOutOfService
          alertKey={alertKey}
          clearPotentialAddress={clearPotentialAddress}
          resetCurrentAction={resetCurrentAction}
          setAlertTypeVisibility={setAlertTypeVisibility}
          toggleAddress={toggleAddress}
        />
      )
      break

    case alertTypes.PHONE_VERIFICATION_INCOMPLETE:
      modal = <PhoneVerificationIncomplete router={router} logout={logout} />
      break

    case alertTypes.REMOVED_PROMO:
      modal = <RemovedPromo toggleRemovedPromo={toggleRemovedPromo} />
      break

    case alertTypes.UNDER_AGE_MINIMUM:
      modal = <UnderAgeMinimum router={router} />
      break

    case alertTypes.UNDER_MINIMUM:
      modal = (
        <UnderMinimum
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          cartMinimum={cartMinimum}
          router={router}
        />
      )
      break

    case alertTypes.VERIFICATION_DECLINED:
      modal = (
        <VerificationDeclined
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          declinedReasons={declinedReasons}
          declinedComment={declinedComment}
        />
      )
      break

    case alertTypes.VERSION_MISMATCH:
      modal = <AppOutdated router={router} />
      break

    default:
      modal = null
  }

  return modal
}

export default Modal
